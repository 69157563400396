import BaseModel from "./BaseModel";
import Client from "./Client";

import StarTypes from "~/lib/data/review_rating_start_types.json";

export default class AccommodationReview extends BaseModel {

    private _rating: number = -1;
    private _text: string = "";
    private _recommendation: string = "";
    private _target_reaction: string = "";
    private _visited_as: string = "";
    private _client: Client | null = new Client();
    private _lang_text: { [k: string]: string } = {};
    private _type_rating: { [k: string]: string } = {};
    private _created_at: Date;
    private _approved: boolean = false;


    constructor() {
        super();
        Object.keys(StarTypes).forEach((key) => {
            this.type_rating[key] = -1;
        })
    }

    get rating(): number {
        return this._rating;
    }

    set rating(value: number) {
        this._rating = value;
    }

    get text(): string {
        return this._text;
    }

    set text(value: string) {
        this._text = value;
    }

    get client(): Client {
        return this._client;
    }

    set client(value: Client) {
        this._client = value;
    }

    get created_at(): Date {
        return this._created_at;
    }

    set created_at(value: Date) {
        this._created_at = value;
    }

    get target_reaction(): string {
        return this._target_reaction;
    }

    set target_reaction(value: string) {
        this._target_reaction = value;
    }


    get recommendation(): string {
        return this._recommendation;
    }

    set recommendation(value: string) {
        this._recommendation = value;
    }

    get lang_text(): { [p: string]: string } {
        return this._lang_text;
    }

    set lang_text(value: { [p: string]: string }) {
        this._lang_text = value;
    }

    get visited_as(): string {
        return this._visited_as;
    }

    set visited_as(value: string) {
        this._visited_as = value;
    }


    get type_rating(): { [p: string]: string } {
        return this._type_rating;
    }

    set type_rating(value: { [p: string]: string }) {
        this._type_rating = value;
    }


    get approved(): boolean {
        return this._approved;
    }

    set approved(value: boolean) {
        this._approved = value;
    }
}
